@use "../designSystem/common/sassVars" as *;

.widget404 {
	table {
		width: 800px;
		margin: 115px auto;

		@media (max-width: $scr-md) {
			width: 100%;
			margin: 80px 0;
		}

		@media (max-width: $scr-sm) {
			margin: 50px 0;
		}
	}

	td {
		vertical-align: middle;
	}

	p {
		font-size: 42px;
		line-height: 50px;
		margin-top: 40px;
		font-weight: 600;
	}

	@media (max-width: $scr-sm) {
		td p {
			margin-top: 0;
		}

		table {
			margin: 10px auto;
		}

		img {
			display: none;
		}
	}
}
